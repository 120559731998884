/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    'Agreement Type':'Agreement Type',
    TERMSANDCONDITIONS: 'Terms and Conditions',
    POLICY: 'Policy',
    PACKAGING_GUIDELINE: 'Packaging Guideline'
}

