import {
    Country as CountryCode,
    Currency as CurrencyCode,
    Gender as GenderCode,
    Language as LanguageCode,
    Locale as LocaleCode,
    Timezone,
    UserTitle,
    UuidV4,
} from '@lazr/openapi-client'
import { Currency, CurrencyProps } from './Currency'
import { Language, LanguageProps } from './Language'
import { Locale, LocaleProps } from './Locale'
import { OrganizationPermission } from '@/app/model/OrganizationPermission'
import { BillToAddress, Lane } from '@/app/model'
import { LabelFormatType, LabelSizeType, Onboarding, UserRole, UserUiType } from '@lazr/enums'

export interface UserInfo {
    user: User | null
    isSignedIn: boolean
    defaultUserCurrency: CurrencyCode
}
interface ImpersonatingInfo {
    active: boolean
    isAdmin: boolean
    organizationId: UuidV4
    organizationName: string
    role: string
}

const DEFAULT_IMPERSONATING_INFO: ImpersonatingInfo = {
    active: false,
    isAdmin: false,
    organizationId: '',
    organizationName: '',
    role: UserRole.ADMIN,
}

const DEFAULT_USER_LANGUAGE: LanguageProps = {
    code: LanguageCode.EN,
    name: 'English',
}

const DEFAULT_USER_LOCALE: LocaleProps = {
    code: LocaleCode.EN_CA,
    name: 'en-CA',
    country: CountryCode.CA,
    currency: CurrencyCode.CAD,
    language: LanguageCode.EN,
}

export interface UserUiSettings {
    tableSettings?: {
        name: string
        type: string
        value: string
    }[]
    // add here if need more settings 
}

export interface UserAgreement {
    id: string
}[]

export interface UserProps {
    id: UuidV4
    address?: string | null
    birthdate?: string | null
    currency: CurrencyProps | null
    defaultBillingCurrency: CurrencyProps | null
    email: string
    emailIsVerified?: boolean | null
    firstName: string | null
    gender: GenderCode | null
    role: UserRole | null
    jobTitle: string | null
    language: LanguageProps | null
    lastName: string | null
    linkedInProfileUrl: string | null
    locale: LocaleProps | null
    longFullName?: string | null
    middleName: string | null
    nickname: string | null
    organizationId: UuidV4
    groupName?: UuidV4
    phoneExtension: string | null
    phoneNumber: string | null
    phoneNumberIsVerified?: boolean | null
    pictureUrl: string | null
    preferredUsername: string | null
    profile: string | null
    shortFullName?: string | null
    timezone: Timezone | null
    title: UserTitle | null
    websiteUrl: string | null
    impersonatingInfo?: ImpersonatingInfo
    updatedAt?: string
    createdAt?: string
    permissions: OrganizationPermission
    lanes: Lane[]
    billToAddresses: BillToAddress[]
    defaultBillingAddressId: UuidV4 | null
    defaultShippingAddressId: UuidV4 | null
    uiSettings?: UserUiSettings | null
    onboarding?: Onboarding
    isDeveloper: boolean
    useImperialUnit: boolean
    labelSizeType: LabelSizeType
    labelFormatType: LabelFormatType
    agreements: UserAgreement[]
}

export class User {
    public id: UuidV4
    public address?: string | null
    public birthdate?: string | null
    public currency: Currency | null
    public defaultBillingCurrency: Currency | null
    public email: string
    public role: UserRole | null
    public emailIsVerified?: boolean | null
    public firstName: string | null
    public gender: GenderCode | null
    public jobTitle: string | null
    public language: Language
    public lastName: string | null
    public linkedInProfileUrl: string | null
    public locale: Locale
    private internalLongFullName?: string
    private internalShortFullName?: string
    public middleName: string | null
    public nickname: string | null
    public organizationId: UuidV4
    public groupName?: UuidV4
    public phoneExtension: string | null
    public phoneNumber: string | null
    public phoneNumberIsVerified?: boolean | null
    public pictureUrl: string | null
    public preferredUsername: string | null
    public profile: string | null
    public timezone: Timezone | null
    public title: UserTitle | null
    public websiteUrl: string | null
    private impersonatingInfo: ImpersonatingInfo
    public updatedAt?: string
    public createdAt?: string
    public permissions: OrganizationPermission
    public lanes: Lane[]
    public billToAddresses: BillToAddress[]
    public defaultBillingAddressId: UuidV4 | null
    public defaultShippingAddressId:  UuidV4 | null
    public uiSettings?: UserUiSettings | null
    public onboarding?: Onboarding
    public isDeveloper: boolean
    public useImperialUnit: boolean
    public labelSizeType: LabelSizeType
    public labelFormatType: LabelFormatType
    public agreements:  UserAgreement[]

    constructor (props: UserProps) {
        this.id = props.id
        this.address = props.address
        this.birthdate = props.birthdate
        this.currency = props.currency ? new Currency(props.currency) : null
        this.defaultBillingCurrency = props.defaultBillingCurrency ? new Currency(props.defaultBillingCurrency) : null
        this.email = props.email
        this.emailIsVerified = props.emailIsVerified
        this.firstName = props.firstName
        this.gender = props.gender
        this.jobTitle = props.jobTitle
        this.language = new Language(props.language ?? DEFAULT_USER_LANGUAGE)
        this.lastName = props.lastName
        this.linkedInProfileUrl = props.linkedInProfileUrl
        this.locale = new Locale(props.locale ?? DEFAULT_USER_LOCALE)
        this.middleName = props.middleName
        this.nickname = props.nickname
        this.organizationId = props.organizationId
        this.groupName = props.groupName
        this.phoneExtension = props.phoneExtension
        this.phoneNumber = props.phoneNumber
        this.phoneNumberIsVerified = props.phoneNumberIsVerified
        this.pictureUrl = props.pictureUrl
        this.preferredUsername = props.preferredUsername
        this.profile = props.profile
        this.timezone = props.timezone
        this.title = props.title
        this.websiteUrl = props.websiteUrl
        this.impersonatingInfo = props.impersonatingInfo || DEFAULT_IMPERSONATING_INFO
        this.updatedAt = props.updatedAt
        this.createdAt = props.createdAt
        this.permissions = props.permissions
        this.lanes = props.lanes
        this.billToAddresses = props.billToAddresses
        this.role = props.role
        this.defaultShippingAddressId = props.defaultShippingAddressId
        this.defaultBillingAddressId = props.defaultBillingAddressId
        this.uiSettings = props.uiSettings ?? null
        this.onboarding = props.onboarding ?? undefined
        this.isDeveloper = props.isDeveloper
        this.useImperialUnit= props.useImperialUnit
        this.labelSizeType= props.labelSizeType
        this.labelFormatType= props.labelFormatType
        this.agreements = props.agreements

        if (props.longFullName) {
            this.longFullName = props.longFullName
        }
        if (props.shortFullName) {
            this.shortFullName = props.shortFullName
        }
    }

    get longFullName (): string {
        if (!this.internalLongFullName) {
            this.internalLongFullName = this.getLongFullName()
        }

        return this.internalLongFullName
    }

    set longFullName (longFullName: string) {
        this.internalLongFullName = longFullName
    }
    setLanes = (lanes: Lane[]) => {
        this.lanes = lanes
    }
    seBillToAddresses = (billToAddresses: BillToAddress[]) => {
        this.billToAddresses = billToAddresses
    }
    private getLongFullName = (): string => {
        const nameParts = []

        if (this.firstName) {
            nameParts.push(this.firstName)
        }

        if (this.middleName) {
            nameParts.push(this.middleName)
        }

        if (this.lastName) {
            nameParts.push(this.lastName)
        }

        return nameParts.join(' ')
    }

    get shortFullName (): string {
        if (!this.internalShortFullName) {
            this.internalShortFullName = this.getShortFullName()
        }

        return this.internalShortFullName
    }

    set shortFullName (shortFullName: string) {
        this.internalShortFullName = shortFullName
    }

    private getShortFullName = (): string => {
        const nameParts = []

        if (this.firstName) {
            nameParts.push(this.firstName)
        }

        if (this.lastName) {
            nameParts.push(this.lastName)
        }

        return nameParts.join(' ')
    }

    resetImpersonation = (): void => {
        this.impersonatingInfo = DEFAULT_IMPERSONATING_INFO
    }
    impersonate = (impersonatingInfo: Omit<ImpersonatingInfo, 'active' | 'role'>): void => {
        this.impersonatingInfo = {
            ...impersonatingInfo,
            active: true,
            role: impersonatingInfo.isAdmin ? 'Admin' : 'User',
        }
    }
    getImpersonatingInfo = (): ImpersonatingInfo => this.impersonatingInfo
}
