import React from 'react'
import {
    RequiredTypography,
    StyledDialog,
    StyledFormContainer,
    StyledSelectionCard,
    StyledTitleTypography,
} from './RequestDemoDesktop.styled'
import { Box, FormControl, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { DialogCloseIcon } from '@/app/ui-new/components/Dialog/Dialog'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import TextField from '@/app/ui-new/components/TextField/TextField'
import ToggleButton from '@/app/ui-new/components/ToggleButton/ToggleButton'
import ToggleButtonGroup from '@/app/ui-new/components/ToggleButton/ToggleButtonGroup'
import { Label } from '@/app/ui-new/components/TextField/TextField.styled'
import ListItem from '@/app/ui-new/components/ListItem/ListItem'
import Select from '@/app/ui-new/components/Select/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox as MuiCheckbox } from '@/app/ui-new/components/Checkbox/Checkbox'
import PhoneNumberField from '@/app/ui-new/components/TextField/PhoneNumberField'
import { BannerDesktop } from '@/app/ui/pages/components/RequestDemo/Desktop/Banner/BannerDesktop'
import { FormFieldErrors, FormFields } from '@/app/ui/pages/components/RequestDemo/RequestDemo'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from '../RequestDemo.i18n'

const RequestDemoDesktop: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useI18n(i18n)

    return (
        <StyledDialog
            fullScreen
            alignment='right'
            open={props.isRequestDemoOpen}
            onClose={() => {props.closeDialog()}}
        >
            <DialogCloseIcon
                onClick={() => {
                    props.resetFormFields()
                    props.resetErrors()
                    props.closeDialog()
                }}
            />
            <Box display={'flex'} flexDirection={'row'}>
                <Box flexGrow={1} height={'100%'}>
                    <BannerDesktop/>
                </Box>
                <StyledFormContainer>
                    <Box>
                        <StyledTitleTypography variant={'h1'}>{t('Request a Demo')}</StyledTitleTypography>
                        <form onSubmit={props.handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            label={t('First Name')}
                                            value={props.formFields.firstName}
                                            fullWidth
                                            size='small'
                                            variant='outlined'
                                            error={props.formFieldErrors.firstNameErrorText !== ''}
                                            helperText={props.formFieldErrors.firstNameErrorText}
                                            onChange={
                                                (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    props.handleInputChange('firstName', e.target.value)
                                                }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            label={t('Last Name')}
                                            value={props.formFields.lastName}
                                            fullWidth
                                            size='small'
                                            variant='outlined'
                                            error={props.formFieldErrors.lastNameErrorText !== ''}
                                            helperText={props.formFieldErrors.lastNameErrorText}
                                            onChange={
                                                (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    props.handleInputChange('lastName', e.target.value)
                                                }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} xs={12}>
                                <Grid item xs={6}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            label={t('Email')}
                                            value={props.formFields.email}
                                            fullWidth
                                            size='small'
                                            variant='outlined'
                                            error={props.formFieldErrors.emailErrorText !== ''}
                                            helperText={props.formFieldErrors.emailErrorText}
                                            onChange={
                                                (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    props.handleInputChange('email', e.target.value)
                                                }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin="normal" required fullWidth>
                                        <PhoneNumberField
                                            fullWidth
                                            size='small'
                                            label={t('Phone')}
                                            variant={'outlined'}
                                            value={props.formFields.phone}
                                            error={!!props.formFieldErrors.phoneErrorText}
                                            helperText={props.formFieldErrors.phoneErrorText}
                                            onChange={(newPhoneNumber: string): void => {
                                                props.handleInputChange('phone', newPhoneNumber)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} xs={12}>
                                <Grid item xs={6}>
                                    <FormControl margin="normal" required fullWidth>
                                        <Label $disabled={false}>
                                            {t('Preferred Language')}
                                        </Label>
                                        <ToggleButtonGroup
                                            value={props.formFields.preferredLanguage}
                                            exclusive
                                            onChange={(event: React.MouseEvent<HTMLElement>, newOption: string) => {
                                                props.handleInputChange('preferredLanguage', newOption)
                                            }}
                                            aria-label="preferred language"
                                        >
                                            <ToggleButton
                                                value={'English'}
                                                aria-label="English"
                                                withSelectedIcon
                                            >
                                                English
                                            </ToggleButton>
                                            <ToggleButton
                                                value={'Français'}
                                                aria-label="French"
                                                withSelectedIcon
                                            >
                                                Français
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        {
                                            props.formFieldErrors.preferredLanguageErrorText &&
                                            <RequiredTypography variant={'caption'}>
                                                {props.formFieldErrors.preferredLanguageErrorText}
                                            </RequiredTypography>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={4} xs={12}>
                                <Grid item xs={12}>
                                    <Box my={6}><hr/></Box>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            label={t('Company Name')}
                                            value={props.formFields.companyName}
                                            fullWidth
                                            size='small'
                                            variant='outlined'
                                            error={props.formFieldErrors.companyNameErrorText !== ''}
                                            helperText={props.formFieldErrors.companyNameErrorText}
                                            onChange={
                                                (e: React.ChangeEvent<HTMLInputElement>): void => {
                                                    props.handleInputChange('companyName', e.target.value)
                                                }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} xs={12}>
                                <Grid item xs={12}>
                                    <FormControl margin="normal" required fullWidth>
                                        <Label $disabled={false}>
                                            {t('Industry')}
                                        </Label>
                                        <Select
                                            value={props.formFields.industry}
                                            onChange={
                                                (e: React.ChangeEvent<{ value: unknown }>) => {
                                                    if (e.target.value === undefined){
                                                        return
                                                    }
                                                    props.handleInputChange('industry', e.target.value as string)
                                                }
                                            }
                                            error={!!props.formFieldErrors.industryErrorText}
                                            helperText={props.formFieldErrors.industryErrorText}
                                        >
                                            <ListItem value={''}>
                                                <em>{t('None')}</em>
                                            </ListItem>
                                            <ListItem value={'Agriculture, forestry, fishing and hunting'}>
                                                {t('Agriculture, forestry, fishing and hunting')}
                                            </ListItem>
                                            <ListItem value={'Mining, quarrying, and oil and gas extraction'}>
                                                {t('Mining, quarrying, and oil and gas extraction')}
                                            </ListItem>
                                            <ListItem value={'Utilities'}>{t('Utilities')}</ListItem>
                                            <ListItem value={'Construction'}>{t('Construction')}</ListItem>
                                            <ListItem value={'Manufacturing'}>{t('Manufacturing')}</ListItem>
                                            <ListItem value={'Wholesale trade'}>{t('Wholesale trade')}</ListItem>
                                            <ListItem value={'Retail trade'}>{t('Retail trade')}</ListItem>
                                            <ListItem value={'Transportation and warehousing'}>
                                                {t('Transportation and warehousing')}
                                            </ListItem>
                                            <ListItem value={'Information and cultural industries'}>
                                                {t('Information and cultural industries')}
                                            </ListItem>
                                            <ListItem value={'Real estate and rental and leasing'}>
                                                {t('Real estate and rental and leasing')}
                                            </ListItem>
                                            <ListItem value={'Professional, scientific and technical services'}>
                                                {t('Professional, scientific and technical services')}
                                            </ListItem>
                                            <ListItem value={'Management of companies and enterprises'}>
                                                {t('Management of companies and enterprises')}
                                            </ListItem>
                                            <ListItem value={'Waste management and remediation services'}>
                                                {t('Waste management and remediation services')}
                                            </ListItem>
                                            <ListItem value={'Health care and social assistance'}>
                                                {t('Health care and social assistance')}
                                            </ListItem>
                                            <ListItem value={'Arts, entertainment and recreation'}>
                                                {t('Arts, entertainment and recreation')}
                                            </ListItem>
                                            <ListItem value={'Accommodation and food services'}>
                                                {t('Accommodation and food services')}
                                            </ListItem>
                                            <ListItem value={'Other services'}>{t('Other services')}</ListItem>
                                            <ListItem value={'Public administration'}>
                                                {t('Public administration')}
                                            </ListItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={4} xs={12}>
                                <Grid item xs={12}>
                                    <FormControl margin="normal" required fullWidth>
                                        <Label $disabled={false}>
                                            {t('Volume of Shipments per Week')}
                                        </Label>
                                        <ToggleButtonGroup
                                            value={props.formFields.volumeOfShipments}
                                            exclusive
                                            onChange={(event: React.MouseEvent<HTMLElement>, newOption: string | null) => {
                                                props.handleInputChange('volumeOfShipments', newOption)
                                            }}
                                            aria-label="volume of shipments"
                                        >
                                            <ToggleButton
                                                value={'Less than 10 per week'}
                                                aria-label='Less 10'
                                                withSelectedIcon>
                                                {t('less 10')}
                                            </ToggleButton>
                                            <ToggleButton
                                                value={'11 to 50 per week'}
                                                aria-label="10-50"
                                                withSelectedIcon>
                                                {t('10-50')}
                                            </ToggleButton>
                                            <ToggleButton
                                                value={'51 to 100 per week'}
                                                aria-label='50-100'
                                                withSelectedIcon>
                                                {t('50-100')}
                                            </ToggleButton>
                                            <ToggleButton
                                                value={'More than 100 per week'}
                                                aria-label='100+' withSelectedIcon>
                                                {t('100+')}
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        {
                                            props.formFieldErrors.volumeOfShipmentsErrorText &&
                                            <RequiredTypography variant={'caption'}>
                                                {props.formFieldErrors.volumeOfShipmentsErrorText}
                                            </RequiredTypography>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <Box mt={4}>
                                        <FormControl margin="none" required fullWidth>
                                            <Label $disabled={false}>
                                                {t('Transportation services used')}
                                            </Label>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container xs={12} spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth >
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'far', 'truck' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('LTL (Local)')}
                                            checked={props.formFields.ltlLocal}
                                            onClick={() =>  props.handleCheckboxChecked('ltlLocal')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth>
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'far', 'truck' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('LTL (National)')}
                                            checked={props.formFields.ltlNational}
                                            onClick={() =>  props.handleCheckboxChecked('ltlNational')}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth>
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'far', 'truck' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('LTL (International)')}
                                            checked={props.formFields.ltlInternational}
                                            onClick={() =>  props.handleCheckboxChecked('ltlInternational')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth>
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'fas', 'truck' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('FTL')}
                                            checked={props.formFields.ftl}
                                            onClick={() =>  props.handleCheckboxChecked('ftl')}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth>
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'fas', 'box-alt' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('Pak')}
                                            checked={props.formFields.pak}
                                            onClick={() =>  props.handleCheckboxChecked('pak')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth>
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'fas', 'box-alt' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('Parcel')}
                                            checked={props.formFields.parcel}
                                            onClick={() =>  props.handleCheckboxChecked('parcel')}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth>
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'fas', 'box-alt' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('Custom Quotes')}
                                            checked={props.formFields.customQuotes}
                                            onClick={() =>  props.handleCheckboxChecked('customQuotes')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin={'none'} fullWidth>
                                        <StyledSelectionCard
                                            icon={<FontAwesomeIcon icon={[ 'far', 'layer-plus' ]} style={{ fontSize: '17.6px' }}/>}
                                            title={t('Other')}
                                            checked={props.formFields.other}
                                            onClick={() =>  props.handleCheckboxChecked('other')}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {
                                props.formFieldErrors.transportationTypeErrorText &&
                                <Box mt={1.5}>
                                    <RequiredTypography variant={'caption'}>
                                        {props.formFieldErrors.transportationTypeErrorText}
                                    </RequiredTypography>
                                </Box>
                            }
                            <Grid container spacing={4} xs={12}>
                                <Grid item xs={12}>
                                    <FormControl margin="dense" fullWidth required>
                                        <Box my={6}><hr/></Box>
                                        <FormControlLabel
                                            control={
                                                <MuiCheckbox
                                                    onChange={() =>  props.handleCheckboxChecked('accept')}
                                                    checked={props.formFields.accept}
                                                    error={!!props.formFieldErrors.acceptErrorText}
                                                />
                                            }
                                            label={
                                                <Typography variant={'body1'}>
                                                    {t('I accept to receive email communications from LAZR.')}<span>*</span>
                                                </Typography>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={4} xs={12}>
                                <Grid item xs={12}>
                                    <Box mt={4}>
                                        <PrimaryButton
                                            type={'submit'}
                                            size='medium'
                                        >
                                            {t('Request a Demo')}
                                        </PrimaryButton>
                                        {
                                            !props.isFormValid &&
                                            <Box mt={1.5}>
                                                <RequiredTypography variant={'caption'}>{t('Missing required fields')}</RequiredTypography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </StyledFormContainer>
            </Box>
        </StyledDialog>
    )
}

export interface Props {
    isRequestDemoOpen: boolean
    closeDialog: () => void
    openSnackbar: (type: 'error' | 'success') => Promise<void>
    formFields: FormFields
    formFieldErrors: FormFieldErrors
    handleInputChange: (name : keyof FormFields, value: string | boolean | null) => void
    handleSubmit: (event: any) => Promise<void>
    isFormValid: boolean
    resetFormFields: () => void
    resetErrors: () => void
    handleCheckboxChecked: (name : keyof FormFields) => void
}

export default RequestDemoDesktop
